
.footer{
    position: relative;
    overflow: auto;
    margin-top: 1rem;
    padding: 1rem;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #161616;
}