
h2, h3, h4, a, li, ul, p, b, .pictureText {
    color: #161616;
}

.SectionContainer {
    padding: 20px;
    background-color: #e5eaf5;
    border-radius: 5px;
    margin-top: 10px;
}

@media (max-width: 800px) {
    .topSection{
        flex-direction: column;
    }
    .edu_purdue_acttivities_wrapper{
        flex-direction: column;
    }
    ul {
        list-style: none;
        padding-left: 0;
    }
}