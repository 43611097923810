.contactTable{
    padding: 10px;
    text-align: center;
    max-width: fit-content;
}

.contactButtons{
  text-align: right;
}

.contactButtons img{
    max-width:  50px;
    padding-left: 15px;
    max-height: auto;
}