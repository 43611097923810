
.SectionSubcontainer{
    display: flex;
}

.SectionsSubcontainer-image img{
    padding: 10px;
    width: 48px;
    height: 48px
}

.SectionContainer-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

p {
    margin: 0 auto;
    text-indent: 15px;
}