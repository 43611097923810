
* {
    margin: 0;
}

.App   {
    max-width: 60%;
    margin: 0 auto;
}

.content {
    margin: 0 auto;
    display:flex;
}

@media (max-width: 800px) {
    .App {
        max-width: 97%;
    }
}