/* base styles */
.pfp{
  width: 20%;
}

.pfp img{
  max-width: 75px;
  border-radius: 5px;
}

.name {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact{
  overflow: auto;
  width: 20%;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid #161616;
  background-color: #e5eaf5;
  border-radius: 5px;
}

@media (max-width: 800px) {

}

.navbar h1 {
  font-size:xx-large;
  color: #161616;
}
.navbar .links {
  margin-left: auto;
}

